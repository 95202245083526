import React from "react"
import { CookiesProvider, withCookies } from "react-cookie"
import { useStaticQuery, graphql } from "gatsby"

import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"
import CookieBaner from "components/CookieBaner/CookieBaner"

import "styles/app.scss"

const Layout = ({ children, isHome, isContest }) => {
  const data = useStaticQuery(graphql`
    {
      allWp {
        nodes {
          pageSettings {
            acfPageSettings {
              headerLinkInnrita
            }
          }
        }
      }
    }
  `)

  return (
    <CookiesProvider>
      <Header
        isHome={isHome}
        isContest={isContest}
        data={data?.allWp?.nodes[0]?.pageSettings?.acfPageSettings}
      />
      <main>{children}</main>
      {!isContest && <Footer />}
      <CookieBaner />
    </CookiesProvider>
  )
}

export default withCookies(Layout)
