import "./header.scss"

import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

const Header = ({ isHome, isContest, data }) => {
  const isBrowser = typeof window !== "undefined"
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    isBrowser &&
      window.addEventListener("scroll", () => {
        if (window.innerWidth < 991) {
          setIsScrolled(window.scrollY > 1)
        } else {
          setIsScrolled(window.scrollY > 31)
        }
      })
  })

  if (typeof window !== "undefined") {
    if (isMenuOpen === true) {
      document.body.classList.add("appleFix")
    } else {
      document.body.classList.remove("appleFix")
    }
  }

  console.log(data?.headerLinkInnrita)

  return (
    <header
      className={`header${isScrolled ? " header__scrolled" : ""}${
        !isHome ? " header__subpage" : ""
      }${isContest ? " header__contest" : ""}`}
    >
      {!isContest && (
        <div className="header__top">
          <div className="container-fluid">
            <a href="/kalaallisut" className="header__top-text">
              Kalaallisut
            </a>
            <a href="/english/" className="header__top-btn">
              ENGLISH
            </a>
            <a href="/mbm" className="header__top-text">
              MBM
            </a>
            <Link to="/burokt" className="header__top-text">
              Búrøkt
            </Link>
          </div>
        </div>
      )}
      <div className="header__bottom">
        <div className="container-fluid">
          <div className="header__bottom-wrapper">
            <Link to="/" className="header__logo">
              <img src={require("assets/images/logo.svg").default} alt="" />
            </Link>
            <nav
              className={`header__nav${isMenuOpen ? " header__nav--open" : ""}`}
            >
              <Link
                to="/"
                className="header__nav-item mobile"
                activeClassName="current"
              >
                HEIM
              </Link>
              <Link
                to="/competition/"
                className="header__nav-item"
                activeClassName="current"
              >
                Jólakappingin
              </Link>
              <Link
                to="/5-godar-grundir/"
                className="header__nav-item"
                activeClassName="current"
              >
                Um mjólk
              </Link>
              <Link
                to="/tidindi/"
                className="header__nav-item"
                activeClassName="current"
              >
                TíĐindi
              </Link>
              <Link
                to="/vorur/"
                className="header__nav-item"
                activeClassName="current"
              >
                Vørur
              </Link>
              <Link
                to="/um-okkum/"
                className="header__nav-item"
                activeClassName="current"
              >
                Um okkum
              </Link>
              <Link
                to="/uppskriftir/"
                className="header__nav-item"
                activeClassName="current"
              >
                Uppskriftir
              </Link>
              <Link
                to="/samband/"
                className="header__nav-item"
                activeClassName="current"
              >
                Samband
              </Link>

              <a
                href="/mbm"
                className="header__nav-item header__nav-item--mobile"
              >
                MBM
              </a>
              <Link
                to="/burokt"
                className="header__nav-item header__nav-item--mobile"
              >
                Búrøkt
              </Link>
              <Link
                to="/storv/"
                className="header__nav-item header__nav-item--mobile"
              >
                Størv
              </Link>
              <Link
                to="/studul/"
                className="header__nav-item header__nav-item--mobile"
              >
                STUĐUL
              </Link>
              <a
                href="/kalaallisut/"
                className="header__nav-item header__nav-item--mobile"
              >
                KALAALLISUT
              </a>
              <a
                href="/english/"
                className="header__nav-item header__nav-item--mobile"
              >
                ENGLISH
              </a>
              {data?.headerLinkInnrita && (
                <a
                  className="button"
                  href={data?.headerLinkInnrita}
                  target="_blank"
                  rel="noreferrer"
                >
                  Innrita
                </a>
              )}
            </nav>
            <button
              aria-label="Open Menu"
              className={`hamburger hamburger--squeeze header__mobile${
                isMenuOpen ? " is-active" : ""
              }`}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {Array(4)
                .fill(null)
                .map((item, index) => (
                  <span key={index} />
                ))}
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
